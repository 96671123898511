/* Footer */
.footer {
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #121212;
	align-items: center;
	padding-top: 30px;
}

.footer-container {
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 500px;
	max-width: 1000px;
}

.footer-containt {
	padding: 0px 30px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap !important;
}

.footer-containt p {
	margin: 20px 0px;
}

.collection {
	display: flex;
	margin-bottom: 25px;
}

.divFields {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0px 3.5px;
}

.labelField {
	width: 100%;
	display: flex;
	flex-direction: column;
	font-size: 14px;
	font-weight: 500;
	margin: 15px 0px 5px 0px;
}

.inputField {
	display: flex;
	width: auto;
	padding: 10px 5px;
	background-color: transparent;
	border-radius: 0px;
	outline: none;
	font-size: 15px;
	font-weight: 400;
	color: white;
}

.inputField:read-write {
	border-color: rgb(255, 255, 255);
}

.inputField:focus {
	outline-color: white;
	outline-width: 0.2px;
	background-color: white;
	color: black;
}

.divBtn .btn {
	padding: 10px 15px;
	font-size: 18px;
	font-weight: bold;
	color: white;
	background-color: #963631;
	border: none;
	cursor: pointer;
}

.divBtn .btn:hover {
	opacity: 0.8;
}

.footer-copyright {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000;
	width: 100%;
	margin-top: 25px;
	padding: 10px 10px;
}

.error-txt {
	color: white;
	text-align: center;
	border: 1px solid goldenrod;
	padding: 5px 15px;
	font-size: 15px;
	margin-top: 15px;
	font-family: 'Abel', sans-serif;
	font-weight: bold;
	display: none;
}

.footer-bizzclan {
	position: absolute;
	right: 10px;
	font-size: 12px;
	color: #fff;
	text-decoration: none;
}

@media (max-width: 850px) {
	.footer-bizzclan {
		position: relative;
		right: 0px;
		font-size: 12px;
		color: #fff;
		text-decoration: none;
	}
}

@media (max-width: 850px) {
	.footer-copyright {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #000;
		width: 100%;
		margin-top: 25px;
		padding: 10px 10px;
	}
}
