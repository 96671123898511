/* Desktop Nav */

.nav-container {
	background: rgba(0, 0, 0, 0.8);
	height: 70px;
	z-index: 300;
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
}

.nav-container nav {
	width: 1000px;
	margin: 0 auto;
	padding: 0 8px;
	height: 100%;
}

nav .desktop-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	list-style: none;
}

nav .mobile_Nav {
	display: block !important;
}

.nav-links {
	display: flex;
}

.nav-mobile-version {
	display: block !important;
	margin-left: -2rem;
	max-width: 24rem;
}

.nav-links li {
	margin: 0 2rem;
}

nav .nav-links li a {
	color: #fff;
	text-decoration: none;
	font-size: 14px;
	opacity: 0.8;
	transition: opacity 400ms;
	cursor: pointer;
}

nav .desktop-nav li a:hover {
	opacity: 1;
}

.logo li img {
	margin-top: 10px;
	width: 25px;
	cursor: pointer;
}

.link-close {
	background: url('../../assets/images/close-icon.svg');
	display: block;
	background-position: center;
	background-size: 17px;
	height: 44px;
	width: 20px;
	background-repeat: no-repeat;
}

/* Overlay */

/* Desktop Nav Animation */
.desktop-nav li {
	transition: all 400ms ease;
}

.desktop-nav.hide li {
	opacity: 0;
	transform: scale(0.8);
	pointer-events: none;
}

/* Overlay Animation */
.overlay {
	transition: all 400ms ease;
}

/* Navigation Menu Items Transition Delay */

.desktop-nav li:nth-of-type(1),
.desktop-nav.hide li:nth-of-type(10) {
	transition-delay: 0ms;
}

.desktop-nav li:nth-of-type(2),
.desktop-nav.hide li:nth-of-type(9) {
	transition-delay: 30ms;
}

.desktop-nav li:nth-of-type(3),
.desktop-nav.hide li:nth-of-type(8) {
	transition-delay: 60ms;
}

.desktop-nav li:nth-of-type(4),
.desktop-nav.hide li:nth-of-type(7) {
	transition-delay: 90ms;
}

.desktop-nav li:nth-of-type(5),
.desktop-nav.hide li:nth-of-type(6) {
	transition-delay: 120ms;
}

.desktop-nav li:nth-of-type(6),
.desktop-nav.hide li:nth-of-type(5) {
	transition-delay: 150ms;
}

.desktop-nav li:nth-of-type(7),
.desktop-nav.hide li:nth-of-type(4) {
	transition-delay: 180ms;
}

.desktop-nav li:nth-of-type(8),
.desktop-nav.hide li:nth-of-type(3) {
	transition-delay: 210ms;
}

.desktop-nav li:nth-of-type(9),
.desktop-nav.hide li:nth-of-type(2) {
	transition-delay: 240ms;
}

.desktop-nav li:nth-of-type(10),
.desktop-nav.hide li:nth-of-type(1) {
	transition-delay: 270ms;
}

/* Hidden Items */
.mobile-nav,
.mobile-search-container {
	display: none;
}

/* Media Queries */
@media (max-width: 1100px) {
	.nav-container nav {
		width: 100%;
		padding: 0 32px;
	}
}

@media (max-width: 768px) {
	.nav-container .desktop-nav {
		flex-direction: column;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 0vh;
		background: #000;
		justify-content: start;
		overflow: hidden;
		z-index: -1;
		transition: all 1000ms ease;
	}

	.nav-container.active .desktop-nav {
		height: 100vh;
		max-width: 26rem;
		margin-left: -1rem;
	}

	.nav-container .desktop-nav li {
		width: 100%;
		/* padding: 0 32px; */
	}

	.nav-container .desktop-nav li:first-child {
		margin-top: 120px;
	}

	.nav-container .desktop-nav .link-logo,
	.nav-container .desktop-nav .link-search,
	.nav-container .desktop-nav .link-bag {
		display: none;
	}

	.nav-container .desktop-nav li a {
		padding: 16px 0;
		display: inline-block;
		border-bottom: 1px solid #616161;
		width: 100%;
		font-size: 17px;
		transform: translateY(-80px);
		opacity: 0;
		transition: all 700ms ease;
	}

	.nav-container.active .desktop-nav li a {
		transform: translateY(0);
		opacity: 1;
	}

	/* Mobile Nav */
	nav .mobile-nav {
		display: flex;
		width: 100%;
		justify-content: space-between;
		list-style: none;
	}

	nav .menu-icon-container {
		width: 20px;
		height: 44px;
		display: flex;
		margin-left: -47px;
		align-items: center;
		cursor: pointer;
	}

	nav .menu-icon {
		position: relative;
		width: 100%;
	}

	nav .menu-icon .line-1,
	nav .menu-icon .line-2 {
		position: absolute;
		height: 1px;
		width: 100%;
		background: #fff;
		transition-property: transform, top;
		transition-delay: 0ms, 160ms;
		transition-duration: 200ms;
	}

	nav .menu-icon .line-1 {
		top: -4px;
	}

	nav .menu-icon .line-2 {
		top: 4px;
	}

	.nav-container.active nav .menu-icon-container .menu-icon .line-1 {
		top: 0;
		transform: rotateZ(45deg);
		transition-property: top, transform;
		transition-delay: 0ms, 160ms;
		transition-duration: 200ms;
	}

	.nav-container.active nav .menu-icon-container .menu-icon .line-2 {
		top: 0;
		transform: rotateZ(-45deg);
		transition-property: top, transform;
		transition-delay: 0ms, 160ms;
		transition-duration: 200ms;
	}

	/* Nav Move Up */
	.nav-container nav.move-up {
		margin-top: -40px;
	}

	/* Cancel Button */
	.mobile-search-container .cancel-btn {
		color: #2997ff;
		font-size: 17px;
		font-weight: 400;
		cursor: pointer;
		width: 0px;
		overflow: hidden;
		transition: all 400ms ease;
	}

	.mobile-search-container .search-bar.active + .cancel-btn {
		padding: 0 16px;
		width: 74px;
	}

	.mobile-search-container .search-bar {
		flex: 1;
	}

	/* Desktop Nav Move Down */
	nav .desktop-nav.move-down li:first-child {
		margin-top: 150px;
	}

	nav .desktop-nav.move-down li {
		opacity: 0;
		pointer-events: none;
	}

	/* Nav Animation */

	.nav-container nav {
		transition: all 400ms ease;
	}
}

input,
div,
span,
a {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.language {
	background: transparent;
	border: none;
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	cursor: pointer;
	opacity: 0.8;
	outline: none;
}

.language:hover {
	opacity: 1;
}

@media (max-width: 768px) {
	.language {
		background: transparent;
		border: none;
		color: #fff;
		font-size: 14px;
		font-weight: 400;
		cursor: pointer;
		opacity: 0.8;
		outline: none;
		padding: 16px 0;
		border-bottom: 1px soloid #616161;
	}
}
